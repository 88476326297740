import React from "react";
import Heading from "../../common/Heading";
import "./recent.css";
import RecentCard from "./RecentCard";
import Post from "../post/Post";
import { HeaderRel } from "./Header-rel";
import { list } from "../../data/Data";
import { Link } from "react-router-dom";

const Recent = ({ language, isHome, isDash }) => {
  // تحديد عنوان العنصر والعنوان الفرعي بناءً على اللغة المحددة
  const title =
    language === "arabic" ? "بدر محمد الصيوان" : "Badr Mohammed Al-Saywan";
  const subtitle =
    language === "arabic"
      ? "خدمات التسويق العقاري"
      : "Real Estate Marketing Services";
  const slogan =
    language === "arabic"
      ? "رقم الترخيص 1100012564"
      : "License Number 1100012564";
  const scrollToTop = () => {
    window.scrollTo(0, 0); // التمرير إلى أعلى الصفحة
  };

  return (
    <>
      <section className="recent padding">
        <div
          className="container"
          style={
            !isHome ? { display: "none" } : isDash ? { display: "none" } : null
          }
        >
          {/* استخدام العنوان والعنوان الفرعي المحدد بناءً على اللغة */}
          {/* 
          <HeaderRel language={language} />
          <Heading title={title} subtitle={slogan} /> */}
        </div>
        <div className="container" style={!isHome ? null : { display: "none" }}>
          <HeaderRel language={language} />
        </div>
        {isDash ? "" : <Post language={language} />}
        <div className="container">
          <Heading title={language === "arabic" ? "العقارات" : "Real Estate"} />
          <p style={{ textAlign: "center" }}>
            {language === "arabic" ? "جميع العقارات" : "All Properties"} : (
            {list.length})
          </p>

          <RecentCard language={language} isHome={isHome} isdash={isDash} />
          <div className="dv-btn">
            <Link to="/real-estate" className="more">
              {/* <button className="btn-more" onClick={scrollToTop}>
                {language === "arabic" ? "جميع العقارات" : "All Properties"}{" "}
                <i className="fa-solid fa-arrow-left"></i>
              </button> */}
              <button
                class="contactButton"
                onClick={scrollToTop}
                style={isHome && !isDash ? null : { display: "none" }}
              >
                {language === "arabic" ? "جميع العقارات" : "All Properties"}
                <div class="iconButton">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Recent;
